import React from 'react'
import styled from 'styled-components'
import { CardSection } from 'ui_elements/CardSection/CardSection'
import { COLORS } from 'COLORS'
import PropTypes from 'prop-types'

const Wrapper = styled(CardSection)`
  background-color: ${COLORS.grey900} !important;
  width: calc(
    100vw - ${props => (props.sidebarOpen ? '230px' : '73px')}
  ) !important; /* TODO - there must be a nicer way to do this */
  height: 50px !important;
  margin: 0 !important;
  position: fixed;
  left: ${props => (props.sidebarOpen ? '230px' : '73px')};
  top: 65px;
  justify-content: ${props => (props.isLoading ? 'center' : 'unset')};
  box-shadow: 0 3px 3px 1px rgba(207, 216, 220, 0.5);
  z-index: 7000;
  transition: all 0.3s linear;

  /* According to Ava, this is a 1 off style - not in the guide so not in TEXT_STYLES */
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLORS.white} !important;
`

export const TrialPeriodStatusBar = props => {
  const {
    globalData: { trialAlerts: launch },
    sidebarOpen
  } = props

  const daysRemaining = launch ? launch.daysRemaining : 0

  // if trial has  expired - show nothing
  if (!daysRemaining) {
    return null
  }

  return (
    <Wrapper sidebarOpen={sidebarOpen}>
      You have {daysRemaining} day{daysRemaining === 1 ? '' : 's'} left on your
      trial
    </Wrapper>
  )
}

TrialPeriodStatusBar.propTypes = {
  globalData: PropTypes.objectOf(PropTypes.any).isRequired,
  sidebarOpen: PropTypes.bool
}

TrialPeriodStatusBar.defaultProps = {
  sidebarOpen: false
}
