import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'

import { TEXT_STYLES } from 'TEXT_STYLES'
import { Button, ButtonType, LoadingIcon } from '@junglescout/edna'

import * as ACTIONS from 'actions/account/mwsSync'
import { CardSection } from 'ui_elements/CardSection/CardSection'
import { MWSAccountSyncDetailsModal } from '../../../components/account/AmazonSellerAccounts/Modals/MWSAccountSyncDetailsModal'
import { TrialPeriodStatusBar } from '../TrialPeriodStatusBar/TrialPeriodStatusBar'

const Wrapper = styled(CardSection)`
  height: 65px !important;
  margin: 0 !important;
  top: 48px;
  justify-content: ${props => (props.isLoading ? 'center' : 'unset')};
  box-shadow: 0 3px 3px 1px rgba(207, 216, 220, 0.5);
  z-index: 7000;
  transition: all 0.3s linear;
`

const Title = styled.header`
  ${TEXT_STYLES.H3Black}
  display: inline;
`

const ProgressBar = styled.progress`
  flex: 1;
  height: 5px;
  margin: 8px 15px 0 15px;
  background-color: #f2f0f0;

  &[value]::-webkit-progress-bar {
    background-color: #f2f0f0;
    border-radius: 2px;
    box-shadow: 0 2px 5px #f2f0f0 inset;
  }

  &[value]::-webkit-progress-value {
    background-color: ${props => props.theme.colors.primary};

    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const ProgressWrapper = styled.div`
  display: flex;
  width: 100%;
`

const Subtitle = styled.div`
  ${TEXT_STYLES.BodyBlack}
`

function shouldLoadStatus(previousAccounts, newAccounts) {
  if (!Array.isArray(newAccounts)) {
    return false
  }

  if (Array.isArray(newAccounts) && !Array.isArray(previousAccounts)) {
    return newAccounts.length > 0
  }

  return newAccounts.length > previousAccounts.length
}

class MwsSyncStatusbar extends Component {
  componentDidUpdate(prevProps) {
    const { account, amazonSellerAccounts, globalData } = this.props

    // if we shouldn't show the status bar, no point loading the data for it
    if (!account.ui.mwsSync.statusBarVisible) {
      return
    }

    // We have to ignore the old Amazon Seller Account data in the following situations
    // - When impersonating
    // - When logging out and in with another user
    let previousAccounts = amazonSellerAccounts
    if (prevProps.globalData.user.id === globalData.user.id) {
      previousAccounts = prevProps.amazonSellerAccounts
    }

    const arrAccounts = amazonSellerAccounts
    const { overall_percentage } = account.mwsSync.status
    // only try to load a status if we have some amazon seller accounts (and we have more accounts than we had in the past)
    if (
      shouldLoadStatus(previousAccounts, arrAccounts) ||
      overall_percentage === undefined
    ) {
      if (!this.props.account.mwsSync.isStatusLoading) {
        this.props.mwsSyncGetStatus()
      }
    }
  }

  onBtnClick(isComplete) {
    if (isComplete) {
      this.props.mwsSyncCloseStatusBar()
    } else {
      this.props.mwsSyncToggleDetailsModal()
    }
  }

  renderLoadingState = () => {
    return (
      <Wrapper isLoading>
        <LoadingIcon color="primary" size="45px" />
      </Wrapper>
    )
  }

  render() {
    const DONT_SHOW_PAGES = ['/login', '/reset-password']
    const {
      amazonSellerAccounts,
      account,
      globalData,
      history: {
        location: { pathname }
      },
      mwsSyncToggleDetailsModal,
      sidebarOpen,
      t
    } = this.props
    const {
      mwsSync,
      ui: {
        mwsSync: { statusBarVisible, syncModal }
      }
    } = account
    // don't render any status bars on the login page
    if (DONT_SHOW_PAGES.some(path => pathname.startsWith(path))) {
      return null
    }

    // this bar should only be shown once the account is synced and the user has dismissed the sync status bar
    if (!statusBarVisible) {
      // only show if the membership is not set to cancel
      const isMembershipSetToCancel =
        // eslint-disable-next-line react/prop-types
        globalData?.membershipInfo?.membershipStatus === 0

      return isMembershipSetToCancel ? (
        <></>
      ) : (
        <TrialPeriodStatusBar
          account={account}
          sidebarOpen={sidebarOpen}
          globalData={globalData}
        />
      )
    }

    if (mwsSync.isStatusLoading) {
      return this.renderLoadingState()
    }

    const percentageComplete = Math.round(
      mwsSync.status.overall_percentage || 0
    )
    const isComplete = percentageComplete >= 100
    const btnText = isComplete
      ? t('generic:Close', 'Close')
      : t('generic:Details', 'Details')
    const subtitleText = isComplete ? (
      <Trans i18nKey="mwsSync:MwsSyncStatusbar.subtitleComplete">
        Amazon Data Sync has finished.
      </Trans>
    ) : (
      t(
        'mwsSync:MwsSyncStatusbar.subtitleInProgress',
        'Amazon account sync in progress'
      )
    )

    return [
      <Wrapper sidebarOpen={sidebarOpen} key="progressBar">
        <InfoWrapper>
          <ProgressWrapper>
            <Title>{percentageComplete}% Complete</Title>
            <ProgressBar max="100" value={percentageComplete}>
              {percentageComplete}%
            </ProgressBar>
          </ProgressWrapper>
          <Subtitle>{subtitleText}</Subtitle>
        </InfoWrapper>
        <Button
          btnType={ButtonType.TERTIARY}
          onClick={() => this.onBtnClick(isComplete)}>
          {btnText}
        </Button>
      </Wrapper>,
      <MWSAccountSyncDetailsModal
        data={mwsSync}
        uiState={syncModal}
        toggleModal={mwsSyncToggleDetailsModal}
        globalAccounts={amazonSellerAccounts}
        key="detailsModal"
      />
    ]
  }
}

const mapStateToProps = ({
  account,
  globalData,
  amazonSellerAccounts: { amazonSellerAccounts }
}) => {
  return { account, globalData, amazonSellerAccounts }
}

export default withTranslation(['mwsSync'])(
  connect(
    mapStateToProps,
    {
      ...ACTIONS
    }
  )(MwsSyncStatusbar)
)
