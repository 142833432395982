import { Component } from 'react'
import { connect } from 'react-redux'

import { DEFAULT_APP_TYPE } from '../../../constants/themes'

export class DefaultAppOnlyComponent extends Component {
  render() {
    const { children, appType } = this.props

    if (appType === DEFAULT_APP_TYPE) {
      return children
    }

    return null
  }
}

const mapStateToProps = ({ globalData: { appType } }) => {
  return { appType }
}

export const DefaultAppOnly = connect(
  mapStateToProps,
  {}
)(DefaultAppOnlyComponent)
