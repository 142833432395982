import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { AMAZON_MWS_PPC_SYNC_STEPS } from 'constants/account'

import {
  showMwsSyncModal,
  mwsSyncSetRegion,
  mwsSyncSetMarketplace,
  mwsSyncSetSellerId,
  mwsSyncSetAuthToken,
  mwsSyncSetAccountName,
  mwsSyncSetSellerEmail,
  mwsPpcSyncCreateNewAccount,
  getAmazonMarketplacesList,
  mwsSyncSetPpcSyncStepSkipped
} from 'actions/account/mwsSync'

import { UnlockSellerFeaturesModal } from '../../../components/account/ConnectToMwsPpcSteps/UnlockSellerFeaturesModal'
import IntroductionStep from '../../../components/account/ConnectToMwsPpcSteps/IntroductionStep'
import { TutorialStep } from '../../../components/account/ConnectToMwsPpcSteps/TutorialStep'
import SelectMarketplaceStep from '../../../components/account/ConnectToMwsPpcSteps/SelectMarketplaceStep'
import PpcSyncStep from '../../../components/account/ConnectToMwsPpcSteps/PpcSyncStep'
import CongratulationsStep from '../../../components/account/ConnectToMwsPpcSteps/CongratulationsStep'
import LoginWithAmazonStep from '../../../components/account/ConnectToMwsPpcSteps/LoginWithAmazonStep'
import AccountSetupStep from '../../../components/account/ConnectToMwsPpcSteps/AccountSetupStep'

export const MwsSyncSteps = ({
  flagData,
  ui,
  mwsSync,
  membershipCode,
  showMwsSyncModal,
  mwsSyncSetRegion,
  mwsSyncSetMarketplace,
  getAmazonMarketplacesList,
  mwsPpcSyncCreateNewAccount,
  mwsSyncSetAccountName,
  mwsSyncSetSellerEmail,
  mwsSyncSetPpcSyncStepSkipped,
  fromSetupPage,
  appType,
  amazonSellerAccountList
}) => {
  const {
    mwsSync: {
      activeModal,
      previousModal,
      isUpgrade,
      mwsSyncStatus,
      ppcSyncStatus,
      ppcSyncWasSkipped
    },
    amazonSellerAccounts
  } = ui

  switch (activeModal) {
    case AMAZON_MWS_PPC_SYNC_STEPS.unlockFeaturesModal:
      return <UnlockSellerFeaturesModal />
    case AMAZON_MWS_PPC_SYNC_STEPS.introduction:
      return (
        <IntroductionStep
          showMwsSyncModal={showMwsSyncModal}
          isUpgrade={isUpgrade}
          membershipCode={membershipCode}
        />
      )
    case AMAZON_MWS_PPC_SYNC_STEPS.tutorialModal:
      return (
        <TutorialStep
          previousModal={previousModal}
          showMwsSyncModal={showMwsSyncModal}
          isUpgrade={isUpgrade}
        />
      )
    case AMAZON_MWS_PPC_SYNC_STEPS.selectRegion:
      return (
        <SelectMarketplaceStep
          previousModal={previousModal}
          showMwsSyncModal={showMwsSyncModal}
          data={mwsSync.data}
          mwsSyncSetRegion={mwsSyncSetRegion}
          mwsSyncSetMarketplace={mwsSyncSetMarketplace}
          getAmazonMarketplacesList={getAmazonMarketplacesList}
          availableMarketplaces={mwsSync.availableMarketplaces}
          hideBackButton={fromSetupPage}
          isUpgrade={isUpgrade}
          fromSetupPage={fromSetupPage}
          flagData={flagData}
        />
      )
    case AMAZON_MWS_PPC_SYNC_STEPS.accountSync:
      return (
        <LoginWithAmazonStep
          previousModal={previousModal}
          showMwsSyncModal={showMwsSyncModal}
          mwsSyncStatus={mwsSyncStatus}
          isUpgrade={isUpgrade}
          data={mwsSync.data}
          errors={mwsSync.errors}
          createSuccess={mwsSync.createSuccess}
          isLoading={mwsSync.isLoading}
          mwsPpcSyncCreateNewAccount={mwsPpcSyncCreateNewAccount}
          mwsSyncSetAccountName={mwsSyncSetAccountName}
          mwsSyncSetSellerEmail={mwsSyncSetSellerEmail}
          flagData={flagData}
          fromSetupPage={fromSetupPage}
          availableMarketplaces={mwsSync.availableMarketplaces}
          appType={appType}
        />
      )
    case AMAZON_MWS_PPC_SYNC_STEPS.ppcSync:
      return (
        <PpcSyncStep
          previousModal={previousModal}
          showMwsSyncModal={showMwsSyncModal}
          setPpcSyncStepSkipped={mwsSyncSetPpcSyncStepSkipped}
          ppcSyncStatus={ppcSyncStatus}
          sellerAccountId={amazonSellerAccounts.editingAccount}
          fromSetupPage={fromSetupPage}
        />
      )
    case AMAZON_MWS_PPC_SYNC_STEPS.accountSetup:
      return (
        <AccountSetupStep
          previousModal={previousModal}
          showMwsSyncModal={showMwsSyncModal}
          isUpgrade={isUpgrade}
          ppcSyncWasSkipped={ppcSyncWasSkipped}
          amazonSellerAccounts={amazonSellerAccountList}
          fromSetupPage={fromSetupPage}
        />
      )
    case AMAZON_MWS_PPC_SYNC_STEPS.congratulations:
      return (
        <CongratulationsStep
          previousModal={previousModal}
          showMwsSyncModal={showMwsSyncModal}
          ppcSyncWasSkipped={ppcSyncWasSkipped}
          fromSetupPage={fromSetupPage}
          flagData={flagData}
        />
      )
    default:
      return null
  }
}

MwsSyncSteps.defaultProps = {
  fromSetupPage: false,
  appType: 'default',
  ui: undefined,
  mwsSync: undefined
}

MwsSyncSteps.propTypes = {
  ui: PropTypes.objectOf(PropTypes.any),
  mwsSync: PropTypes.objectOf(PropTypes.any),
  fromSetupPage: PropTypes.bool,
  appType: PropTypes.string
}

const mapStateToProps = ({
  account: { ui, mwsSync },
  amazonSellerAccounts: { amazonSellerAccounts },
  globalData: {
    flagData,
    appType,
    membershipInfo: { code }
  }
}) => {
  return {
    ui,
    mwsSync,
    flagData,
    appType,
    membershipCode: code,
    amazonSellerAccountList: amazonSellerAccounts
  }
}

export default withTranslation(['mwsSync'])(
  connect(
    mapStateToProps,
    {
      showMwsSyncModal,
      mwsSyncSetRegion,
      mwsSyncSetMarketplace,
      mwsSyncSetSellerId,
      mwsSyncSetAuthToken,
      mwsSyncSetAccountName,
      mwsSyncSetSellerEmail,
      mwsPpcSyncCreateNewAccount,
      getAmazonMarketplacesList,
      mwsSyncSetPpcSyncStepSkipped
    }
  )(MwsSyncSteps)
)
