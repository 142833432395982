import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, Tooltip } from '@junglescout/edna'
import { showMwsSyncModal } from 'actions/account/mwsSync'

import { AMAZON_MWS_PPC_SYNC_STEPS } from 'constants/account'
import { Features } from 'constants/feature_names'

import { getPricingPageUrl } from 'helpers/account'
import { useFeatureFlag } from 'hooks/feature_flag'

export const ConnectToAmazonButton = ({
  btnType,
  btnText,
  className,
  dataId,
  iconName,
  isLoading,
  margin,
  onClick,
  width
}) => {
  const { t } = useTranslation(['generic', 'account'])
  const {
    membershipInfo: { hasActiveMembership, group },
    user: {
      checkout_locale,
      currency,
      selected_language,
      is_account_owner: isAccountOwner
    }
  } = useSelector(state => state.globalData)

  const { amazonSellerAccounts, sellerDataAvailable } = useSelector(
    state => state.amazonSellerAccounts
  )

  const isSellerFeaturesMaintenance = useFeatureFlag(
    Features.SELLER_FEATURES_MAINTENANCE
  )
  const isSellerFeaturesDisabled =
    !sellerDataAvailable || isSellerFeaturesMaintenance

  const dispatch = useDispatch()

  const defaultButtonText = hasActiveMembership
    ? t('generic:connectToAmazon', 'Connect to Amazon')
    : t('generic:UpgradeNow', 'Upgrade Now')

  const buttonText = btnText || defaultButtonText

  const noActiveMembershipDestination = getPricingPageUrl(
    checkout_locale,
    currency,
    selected_language,
    group
  )

  const defaultClick = () => {
    // TODO: This uses hasActiveMembership for now, but we'll eventually check
    // to see if a specific feature is gated or not. We'll likely have to pass the feature
    // key in as a prop & check it here against the global state.
    if (!hasActiveMembership) {
      return window.location.assign(noActiveMembershipDestination)
    }

    if (amazonSellerAccounts?.length > 0) {
      dispatch(showMwsSyncModal(AMAZON_MWS_PPC_SYNC_STEPS.selectRegion))
    } else {
      dispatch(showMwsSyncModal(AMAZON_MWS_PPC_SYNC_STEPS.introduction, true))
    }
  }

  return (
    <Tooltip
      contentClasses="medium right"
      size="medium"
      disabled={
        isSellerFeaturesDisabled
          ? false
          : isAccountOwner || !hasActiveMembership
      }
      content={
        isSellerFeaturesDisabled
          ? t(
              'account:AmazonSellerAccounts.sellerFeatureDisabled',
              'Adding an Amazon seller account is currently unavailable. Please try again later.'
            )
          : t(
              'account:AmazonSellerAccounts.tooltipAddNewDisabled',
              'Adding or editing Amazon accounts is limited to the primary account holder.'
            )
      }>
      <span>
        <Button
          btnType={btnType}
          className={className}
          dataId={dataId}
          iconName={iconName}
          isLoading={isLoading}
          margin={margin}
          width={width}
          disabled={
            isSellerFeaturesDisabled || (!isAccountOwner && hasActiveMembership)
          }
          onClick={() => {
            const destination = hasActiveMembership
              ? 'modal'
              : noActiveMembershipDestination
            onClick({ buttonText, destination })
            defaultClick()
          }}>
          {buttonText}
        </Button>
      </span>
    </Tooltip>
  )
}

ConnectToAmazonButton.defaultProps = {
  btnType: ButtonType.PRIMARY,
  btnText: undefined,
  className: '',
  dataId: null,
  iconName: undefined,
  isLoading: false,
  onClick: () => {},
  margin: undefined,
  width: undefined
}

ConnectToAmazonButton.propTypes = {
  btnType: PropTypes.string,
  btnText: PropTypes.string,
  className: PropTypes.string,
  dataId: PropTypes.string,
  iconName: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  margin: PropTypes.string,
  width: PropTypes.string
}
